import React, { useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import './Calculator.css';

const Calculator = () => {
    const [loanAmount, setLoanAmount] = useState('');
    const [downPayment, setDownPayment] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [loanTerm, setLoanTerm] = useState('');
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [monthlyPaymentWithPMI, setMonthlyPaymentWithPMI] = useState(null);
    const [totalMonthlyPayment, setTotalMonthlyPayment] = useState(null);

    const calculateMortgage = (e) => {
        e.preventDefault();

        // Validation: Check if any of the fields are empty
        if (loanAmount === '' || interestRate === '' || loanTerm === '' || downPayment === '') {
            alert('Please fill in all fields');
            return;
        }

        // Convert strings to floats
        const principal = parseFloat(loanAmount) - parseFloat(downPayment);
        const calculatedInterest = parseFloat(interestRate) / 100 / 12;
        const calculatedPayments = parseFloat(loanTerm) * 12;

        // Monthly payment formula
        const x = Math.pow(1 + calculatedInterest, calculatedPayments);
        const monthly = (principal * x * calculatedInterest) / (x - 1);

        // PMI Calculation
        const loanWithoutDownPayment = parseFloat(loanAmount);
        let pmi = 0;
        if (downPayment / loanWithoutDownPayment < 0.2) {
            pmi = Math.ceil((loanWithoutDownPayment / 100000) * 55); // $55 for every $100,000 financed
        }

        // Calculate taxes (assumed at 1% annually for simplicity)
        const residentialTax = (loanWithoutDownPayment * 0.01) / 12;

        // Total monthly payment including PMI and tax
        const totalPayment = monthly + pmi + residentialTax;

        if (isFinite(monthly)) {
            setMonthlyPayment(monthly.toFixed(2)); // Principal and interest only
            setMonthlyPaymentWithPMI((monthly + pmi).toFixed(2)); // Including PMI
            setTotalMonthlyPayment(totalPayment.toFixed(2)); // Including PMI and taxes
        } else {
            setMonthlyPayment('Invalid inputs');
            setMonthlyPaymentWithPMI('Invalid inputs');
            setTotalMonthlyPayment('Invalid inputs');
        }
    };

    return (
        <>
            <Navbar />
            <div className='mortgage-calculator-container'>
                <h1>Mortgage Calculator</h1>
                <form onSubmit={calculateMortgage} className="mortgage-form">
                    <div className="form-group">
                        <label htmlFor="loanAmount">Loan Amount ($):</label>
                        <input 
                            type="number" 
                            id="loanAmount" 
                            value={loanAmount} 
                            onChange={(e) => setLoanAmount(e.target.value)} 
                            placeholder="Enter loan amount"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="downPayment">Down Payment ($):</label>
                        <input 
                            type="number" 
                            id="downPayment" 
                            value={downPayment} 
                            onChange={(e) => setDownPayment(e.target.value)} 
                            placeholder="Enter down payment"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="interestRate">Annual Interest Rate (%):</label>
                        <input 
                            type="number" 
                            id="interestRate" 
                            value={interestRate} 
                            onChange={(e) => setInterestRate(e.target.value)} 
                            placeholder="Enter interest rate"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="loanTerm">Loan Term (Years):</label>
                        <input 
                            type="number" 
                            id="loanTerm" 
                            value={loanTerm} 
                            onChange={(e) => setLoanTerm(e.target.value)} 
                            placeholder="Enter loan term"
                        />
                    </div>
                    <button type="submit" className="calculate-btn">Calculate</button>
                </form>

                {monthlyPayment && (
                    <div className="result">
                        <h2>Monthly Payment (Principal & Interest ONLY): ${monthlyPayment}</h2>
                        {monthlyPaymentWithPMI !== monthlyPayment && (
                            <>
                            <h2>Monthly Payment (Principal & Interest, and PMI): ${monthlyPaymentWithPMI}</h2>
                            <h3>Since you are putting LESS than 20% down, you will need to pay PMI, which tends to be about $55 per month for every $100,000 financed (until you have paid off 20% of your loan). This could add $55 to your monthly payment.</h3>
                            </>
                        )}
                        {totalMonthlyPayment && (
                            <h2>Estimated Monthly Payment (Including Tax): ${totalMonthlyPayment}</h2>
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Calculator;
