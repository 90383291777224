import React from 'react';
import './Mailchimp.css';

const Mailchimp = ({ onClose }) => {
  return (
    <div id="mc_embed_shell">
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
        rel="stylesheet"
        type="text/css"
      />
      <style>{`
        #mc_embed_signup {
          background: #f8f8f8;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          clear: left;
          max-width: 600px;
          min-width:400px;
          border-radius: 15px;
          color: #A80532;
          padding-top: 0px;
          margin-top: 0px;
          
        }
        /* Add your own Mailchimp form style overrides here */
        #mc_embed_shell {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f4f4f4; /* Optional, for styling */
            padding: 40px;
        }

        #mc-embedded-subscribe {
            background-color: #fff !important; 
            color: #A80532 !important; 
            padding: 0px 30px !important; 
            border: none !important;
            border-radius: 30px !important; 
            font-size: 1rem !important;
            cursor: pointer !important;
            transition: all 0.3s ease !important;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3) !important; 
            outline: none !important;
            display: inline-block !important;
        }

        #mc-embedded-subscribe:hover {
            background-color: #8e0028 !important; 
            color: white !important;
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4) !important; 
            transform: translateY(-2px) !important; /* Slight lift effect on hover */
        }

        #mc-embedded-subscribe:active {
            transform: translateY(1px) !important; /* Button sinks slightly when clicked */
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3) !important; /* Reduced shadow on click */
        }
        #mc-embedded-subscribe-form {
            margin-top: 0px;
            padding-top: none;    
        }
      `}</style>
      <div id="mc_embed_signup" className='mc_embed_signup'>
      <button className="close-button" onClick={onClose}>
          ×
        </button>
        <form
          action="https://outlook.us13.list-manage.com/subscribe/post?u=8be7c7fa5f378f4311c25d805&amp;id=b8422910e8&amp;f_id=00c7f7e6f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <h2 className='mailchimp-h2'>Subscribe To "A Listing Agent's Journal" Newsletter</h2>
            <div className="indicates-required">
              <span className="asterisk">*</span> indicates required
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">First Name</label>
              <input type="text" name="FNAME" className="text" id="mce-FNAME" />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-LNAME">Last Name</label>
              <input type="text" name="LNAME" className="text" id="mce-LNAME" />
            </div>
            <div hidden>
              <input type="hidden" name="tags" value="7076874" />
            </div>
            <div id="mce-responses" className="clear foot">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              ></div>
            </div>
            <div
              aria-hidden="true"
              style={{ position: 'absolute', left: '-5000px' }}
            >
              <input
                type="text"
                name="b_8be7c7fa5f378f4311c25d805_b8422910e8"
                tabIndex="-1"
              />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  value="Subscribe"
                />
                <p style={{ margin: '0 auto' }}>
                  <a
                    href="http://eepurl.com/iW4pRs"
                    title="Mailchimp - email marketing made easy and fun"
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        backgroundColor: 'transparent',
                        borderRadius: '4px',
                      }}
                    >
                      <img
                        className="refferal_badge"
                        src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                        alt="Intuit Mailchimp"
                        style={{
                          width: '220px',
                          height: '40px',
                          display: 'flex',
                          padding: '2px 0',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function($) {
            window.fnames = [];
            window.ftypes = [];
            fnames[0] = 'EMAIL';
            ftypes[0] = 'email';
            fnames[1] = 'FNAME';
            ftypes[1] = 'text';
            fnames[2] = 'LNAME';
            ftypes[2] = 'text';
            fnames[3] = 'ADDRESS';
            ftypes[3] = 'address';
            fnames[4] = 'PHONE';
            ftypes[4] = 'phone';
            fnames[5] = 'BIRTHDAY';
            ftypes[5] = 'birthday';
            fnames[6] = 'MMERGE6';
            ftypes[6] = 'text';
            fnames[7] = 'STAGE';
            ftypes[7] = 'dropdown';
            fnames[8] = 'SOURCE';
            ftypes[8] = 'dropdown';
            fnames[9] = 'AGENT';
            ftypes[9] = 'dropdown';
            fnames[10] = 'A_EMAIL';
            ftypes[10] = 'text';
            fnames[11] = 'A_PHONE';
            ftypes[11] = 'text';
            fnames[12] = 'PRICE';
            ftypes[12] = 'number';
            fnames[13] = 'CONTACTED';
            ftypes[13] = 'dropdown';
            fnames[14] = 'ADDED_FUB';
            ftypes[14] = 'date';
            fnames[15] = 'GENDER';
            ftypes[15] = 'dropdown';
          }(jQuery));
          var $mcj = jQuery.noConflict(true);
        `,
        }}
      />
    </div>
  );
};

export default Mailchimp;