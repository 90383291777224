const ROUTES = {
    HOME: "/",
    CONTACT: "/contact",
    ABOUT: "/about",
    CALCULATOR: "/calculator",
    NEWS: "/news",
    PRIVACY: "/privacy",
    UNAUTHORIZED: "/unauthorized"
  };
  
  export default ROUTES;