import './Home.css';
import React from 'react';
import Navbar from '../../Components/Navbar';
import Portrait from '../../Images/fullPortrait.jpg';
import Footer from '../../Components/Footer'
/*

Flow should be
Nav ->
SECTION Image bg with text ->
SECTION calc - >
SECTION about

*/
const Home = () => {
  return (
    <>
    <Navbar/>
    <body>
        <div className='main'>
            <section className='sec1'>
                <h1>Let's get you home.</h1>
                <h2>Xavier Guerrero</h2>
                <h3>DRE 02158644</h3>
                <a href='/contact'>
                <button>Contact Me</button>
                </a>
            </section>
            <section className='sec2'>
                <h2>Real Estate Services in</h2><h1 className='lessmargin'>San Bernardino & Riverside</h1><h2 className='lessmarginh2'>Counties</h2>
                <div className="sec2-content">
                    <div className="sec2-left">
                        <div className='portrait-container'>
                        <img src={Portrait} alt="Portrait of Xavier Guerrero Leon" className="portrait" />
                        </div>
                    </div>
                    <div className="sec2-right">
                        <p>I specialize in residential real estate throughout Southern California's Inland Empire, including Riverside, San Bernardino, and Ontario. I offer personalized services for both buyers and sellers, handling everything from first-time home purchases to property listings and negotiations. My goal is to provide a seamless, supportive experience for every client.</p>
                        <a href="/about">
                            <button className="squircular-button">Read Bio</button>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    <Footer />
    </body>
    </>
  );
};

export default Home;