import './App.css'
function Privacy () {
    return (
<div className="privacy"><h1>Privacy Policy</h1>

<p>Your privacy is important to us. This privacy statement explains the personal data Xavier Guerrero collects, how we use that data, and your rights regarding your personal data.</p>

<h2>1. Information We Collect</h2>
<p>We collect the following types of personal information:</p>
<ul>
  <li>Name, email address, and phone number (when you submit a contact form)</li>
</ul>

<h2>2. How We Use Your Information</h2>
<p>We use your personal information for the following purposes:</p>
<ul>
  <li>To respond to your inquiries and provide the requested services</li>
  <li>To communicate with you about our services, promotions, and offers</li>
  <li>To comply with legal obligations</li>
</ul>

<h2>3. Sharing Your Information</h2>
<p>We do not sell your personal information to third parties. We may share your information with:</p>
<ul>
  <li>Service providers who assist us in delivering our services</li>
  <li>Law enforcement agencies or regulators when required by law</li>
</ul>

<h2>4. Your Rights</h2>
<p>You have the following rights regarding your personal data:</p>
<ul>
  <li>Access: You may request a copy of the personal data we hold about you</li>
  <li>Correction: You may request that we correct any inaccurate data</li>
  <li>Deletion: You may request that we delete your personal data</li>
  <li>Opt-out: You can opt-out of marketing communications at any time by clicking the "unsubscribe" link in emails</li>
</ul>

<h2>5. Data Security</h2>
<p>We implement reasonable security measures to protect your personal data. However, no method of transmission over the internet is completely secure.</p>

<h2>6. Changes to This Privacy Policy</h2>
<p>We may update this privacy policy periodically. We will notify you of any significant changes by posting a notice on our website or contacting you directly.</p>

<h2>7. Contact Us</h2>
<p>If you have any questions about this privacy policy, please contact us at:</p>
<p>Email: xavier.guerr@outlook.com</p>
<p>Phone: 909.302.0239</p>
<p>Address: 11175 Azusa Ct, Suite 110, Rancho Cucamonga, CA 91730</p>
    </div>)};

    export default Privacy;