import React from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import './About.css';
import Portrait from '../../Images/fullPortrait.jpg'

const About = () => {
  return (
    <>
    <Navbar />
    <div className='about-main'>
                <h1>About Me</h1>
                <div className="main-content">
                    <div className="main-left">
                        <img src={Portrait} alt="Portrait of Xavier Guerrero Leon" className="portrait-square" />
                    </div>
                    <div className="main-right">
                        <p>Hi, I’m Xavier, a dedicated real estate agent and proud dad of three with a deep passion for helping families find not just a house, but a place they can truly call home. I bring a personal touch to every transaction, taking the time to understand your unique needs and goals. Whether you’re buying your first home, upgrading to accommodate a growing family, selling to start a new chapter, or looking for investment opportunities, I’m here to guide you through a smooth, stress-free process.</p>
                        <p>My approach goes beyond transactions; it’s about building relationships. I take pride in creating a client-focused experience where trust, open communication, and your satisfaction are my top priorities. I stay up-to-date with market trends, local insights, and the latest strategies to ensure you get the most out of your real estate journey. I’m committed to providing expert advice and tailored solutions that give you the confidence to make informed decisions.</p>
                        <p>Outside of real estate, I’m all about family. When I’m not working with clients, I’m spending quality time with my three kids, exploring local spots, and cheering them on at their sports and school events. I believe that being an active part of the community I serve makes me a better real estate agent, allowing me to offer local knowledge that goes beyond the market data.</p>
                        <p>Real estate isn’t just about buying or selling properties—it’s about finding the right fit for your lifestyle and future. I’m here to make sure your experience is as rewarding as the outcome. Let’s work together to make your real estate dreams a reality. Don’t hesitate to reach out and start the conversation today!</p>
                    </div>
                </div>
        </div>
    <Footer />
    </>
  );
};

export default About;