export const NavData = [

    {
        path: '/about',
        content: 'About',
        icon: 'fa-solid fa-circle-question'

    },
    {
        path: '/news',
        content: 'News',
        icon: 'fa-solid fa-newspaper'
    },
    {
        path: '/calculator',
        content: 'Calculator',
        icon: 'fa-solid fa-calculator'
    },

    {
        path: '/contact',
        content: "Let's Connect",
        icon: 'fa-solid fa-address-book'

    },

]