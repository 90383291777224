import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import Mailchimp  from '../../Components/Mailchimp';

import './News.css'
const News = () => {
  const [blogs, setBlogs] = useState([]);
  const [showMailchimp, setShowMailchimp] = useState(true); // State to toggle Mailchimp visibility

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await fetch('/api/rss');  // Backend endpoint
      const text = await response.text();
        
    //   console.log('Response content type:', response.headers.get('Content-Type'));

      // Use a DOMParser to convert RSS feed to DOM
      const parser = new DOMParser();
      const xml = parser.parseFromString(text, 'text/xml');

      // Extract blog items
      const items = Array.from(xml.querySelectorAll('item')).map((item) => ({
        title: item.querySelector('title')?.textContent || '',
        link: item.querySelector('link')?.textContent || '',
        pubDate: item.querySelector('pubDate')?.textContent || '',
      }));

    //   console.log(xml);

      setBlogs(items);
    };

    fetchBlogs().catch((err) => console.error('Error fetching blogs:', err));
  }, []);

  return (
    <>
    <Navbar />
    <body className='container-news'>
    {showMailchimp && <Mailchimp onClose={() => setShowMailchimp(false)} />}


    <div className="blogs">
    <h1>Latest Blogs</h1>

      {blogs.length === 0 ? (
        <p>Loading blogs...</p>
      ) : (
        blogs.map((blog, index) => (
          <div key={index} className="blog-item">
            <h2 className="blog-title">{blog.title}</h2>
            <p className='blog-date'>{new Date(blog.pubDate).toLocaleDateString()}</p>
            <a href={blog.link} target="_blank" rel="noopener noreferrer">
            <button>Read More
            </button></a>
            
          </div>
        ))
      )}
    </div>
    </body>
    <Footer />
    </>
  );
};

export default News;