import { Link, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Contact from './Pages/Contact/Contact';
import About from './Pages/About/About';
import Calculator from './Pages/Calculator/Calculator';
import News from './Pages/News/News';
import Privacy from './privacy';
import ROUTES from './Routes';



const App = () => {
    
  return (
    <>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.CONTACT} element={<Contact />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.CALCULATOR} element={<Calculator />} />
        <Route path={ROUTES.NEWS} element={<News />} />
        <Route path={ROUTES.PRIVACY} element={<Privacy />} />

        <Route path={ROUTES.UNAUTHORIZED} element={
          <>
            <h1>404 Not Found</h1>
            <h3><Link to="/">Return home</Link></h3>
          </>
        } />

      </Routes>
    
    </>
  );
}

export default App;