import React, { useState } from 'react';
import Navbar from '../../Components/Navbar';
import Footer from '../../Components/Footer';
import './Contact.css'
const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    consent: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, consent: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.consent) {
      alert("You must agree to the terms to submit the form.");
      return;
    }

    const response = await fetch('/api/send_email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    const result = await response.json();
    if (result.success) {
      alert('Message sent successfully!');
    } else {
      alert('Error sending message.');
    }
  };

  return (
    <>
    <Navbar/>
    <body className='std-body'>
    <div className="form-container">
            <h1>Contact Me</h1>
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </div>

                {/* Opt-in checkbox */}
                <div className="checkbox-wrapper-7">
                    <input
                        type="checkbox"
                        id="consent"
                        name="consent"
                        checked={formData.consent}
                        onChange={handleCheckboxChange}
                        className='tgl tgl-ios'
                        required
                    />
                        <label className="tgl-btn" htmlFor="consent"/>
                        <p>I agree to be contacted by Xavier Guerrero via call, email, and text. To opt out, reply "STOP" at any time or click the unsubscribe link in the emails. Msg/data rates may apply. Msg frequency varies. <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy & Terms of Service</a>.</p>

                </div>

                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </div>
        </body>
    <Footer/>
    </>
  );
};

export default Contact;
